import React, { Component } from "react"
import { FaChevronLeft, FaGlobe } from "react-icons/fa"
import { injectIntl } from "react-intl"
import styled from "styled-components"
import { styles } from "../globals"

class SelectLang extends Component {
  slideToggle() {
    document.querySelector(".dropdown").classList.toggle("active")
  }
  slideClose() {
    document.querySelector(".dropdown").classList.remove("active")
  }

  render() {
    const {
      intl: { locale },
    } = this.props
    const { change, items } = this.props
    return (
      <SelectLangWrapper change={change}>
        <div
          className="dropdown"
          onBlur={this.slideClose}
          onClick={this.slideToggle}
          tabIndex="-1"
        >
          <div className="select">
            <FaGlobe className="globe-icon" />
            <span id="show-selected">{locale}</span>
            <FaChevronLeft className="chevron-left-icon" />
          </div>
          <input id="hidden-input" type="hidden" name="language" />
          <ul className="dropdown-menu">
            {items.map((item, index) => (
              <li data-lang={item.lang} key={index}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      </SelectLangWrapper>
    )
  }
}

const SelectLangWrapper = styled.div`
  margin-left: 0.8rem;
  .chevron-left-icon {
    margin-left: 0.2rem;
    padding-top: 0.3rem;
  }
  .globe-icon {
    padding-top: 0.2rem;
    margin-right: 0.3rem;
  }
  .dropdown {
    color: ${styles.mainNavbarColor};
    padding-left: 0.3rem;
    position: relative;
  }
  .dropdown .select {
    cursor: pointer;
    display: block;
    padding-top: 0.8rem;
  }
  .dropdown .select > i {
    color: #888;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
  }
  .dropdown:active {
    background-color: #f8f8f8;
  }

  .dropdown.active .dropdown-menu {
    height: 220px;
  }

  .dropdown.active .select .chevron-left-icon {
    transform: rotate(-90deg);
  }
  .dropdown .dropdown-menu {
    background: antiquewhite;
    position: absolute;
    width: 100%;
    height: 0;
    left: 0;
    margin: 0;
    overflow: hidden;
    ${styles.transform({ property: "height" })};
    z-index: 9;
  }
  .dropdown .dropdown-menu li {
    cursor: pointer;
    transition: all 1s ease-in-out;
  }
  .dropdown .dropdown-menu {
    padding: 0;
    list-style: none;
  }
  .dropdown .dropdown-menu li {
    padding: 0;
  }
  .dropdown .dropdown-menu li > a {
    color: ${styles.mainNavbarColor};
    display: inline-block;
    width: 100%;
    padding: 10px;
  }
  .dropdown .dropdown-menu li > a:hover {
    color: ${styles.mainPink};
  }
  .dropdown .dropdown-menu li:hover {
    background-color: grey;
  }
  /* Needed too overide, since the font weere serif */
  #show-selected {
    font-family: "Verdana Round", sans-serif;
  }
  @media (min-width: ${styles.mediumScreenBreakpoint}) {
    .globe-icon {
      margin-left: 0.3rem;
      color: ${styles.mainPink};
    }
    .dropdown {
      padding: 0;
      width: 4.5rem;
      display: inline-block;
      background-color: transparent;
      position: relative;
      color: ${props =>
        !props.change ? styles.mainWhite : styles.mainNavbarColor};
      font-weight: 900;
      height: 100%;
      text-align: center;
      transition: all 0.3s ease-in-out;
    }
    .dropdown.active .dropdown-menu {
      height: 200px;
    }
    .dropdown .select {
      cursor: pointer;
      display: block;
    }
    .dropdown .select > i {
      color: #888;
      cursor: pointer;
    }
    .dropdown:hover {
      background: ${styles.mainWhite};
      color: ${styles.mainPink};
    }
    .dropdown:active {
      background-color: #f8f8f8;
    }

    .dropdown.active:hover,
    .dropdown.active {
      box-shadow: 0 0 4px rgb(204, 204, 204);
      border-radius: 5px 5px 0 0;
      background-color: #f8f8f8;
    }
    .dropdown.active .select .chevron-left-icon {
      transform: rotate(-90deg);
    }
    .dropdown .dropdown-menu {
      position: absolute;
      background-color: #fff;
      width: 100%;
      height: 0;
      left: 0;
      margin: 0;
      box-shadow: 0 1px 2px rgb(204, 204, 204);
      border-radius: 0 1px 5px 5px;
      overflow: hidden;
      ${styles.transform({ property: "height" })};
      z-index: 9;
    }
    .dropdown .dropdown-menu li {
      padding: 10px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }
    .dropdown .dropdown-menu {
      padding: 0;
      list-style: none;
    }
    .dropdown .dropdown-menu li {
      padding: 0;
    }
    .dropdown .dropdown-menu li > a {
      color: ${styles.mainNavbarColor};
      display: inline-block;
      width: 100%;
      padding: 10px;
    }
    .dropdown .dropdown-menu li:hover {
      background-color: #f2f2f2;
    }
    .dropdown .dropdown-menu li:active {
      background-color: #e2e2e2;
    }
  }
`
export default injectIntl(SelectLang)
