import React, { Component } from "react"
import config from "../../../content/meta/config"
import avatar from "../../images/jpg/avatar.jpg"
import LocalizedLink from "../LocalizedLink"
import { FaAngleDown, FaAngleUp } from "react-icons/fa"
import styled from "styled-components"
import { styles } from "../../globals"

import themeObjectFromYaml from "../../theme/theme.yaml"

const theme = themeObjectFromYaml

export default class NavbarHeader extends Component {
  render() {
    const { handleNavbar, open } = this.props
    return (
      <HeaderWrapper change={this.props.change}>
        <LocalizedLink to="/" className="logoType">
          <div className="logo">
            <img src={avatar} alt={config.siteTitle} />
          </div>
          <div className="type">
            <h1>{config.headerTitle}</h1>
            <h2>{config.headerSubTitle}</h2>
          </div>
        </LocalizedLink>

        {!open ? (
          <FaAngleDown
            className="toggle-icon"
            onClick={() => {
              handleNavbar()
            }}
          />
        ) : (
          <FaAngleUp
            className="toggle-icon"
            onClick={() => {
              handleNavbar()
            }}
          />
        )}
      </HeaderWrapper>
    )
  }
}

const HeaderWrapper = styled.div`
  padding: 0.6rem 0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logoType {
    display: flex;
    transform: scale(0.9);
    margin-left: -0.8rem;
  }

  .type h1,
  .type h2 {
    border: solid 0.2rem hotpink;
    color: ${styles.mainNavbarColor};
    padding: 0.4rem 1rem;
    transform: rotate(-5deg);
  }

  .type h1 {
    display: inline-block;
    font-size: ${theme.font.size.m};
    font-weight: ${theme.font.weight.standard};
    margin: 0 0 -3px -3px;
    border-bottom: none;
    text-transform: uppercase;
  }

  .type h2 {
    font-weight: bold;
    font-size: 1.2rem;
    letter-spacing: 0;
    margin: 0;
  }

  .logo {
    border-radius: 50%;
    border: 2px solid white;
    display: inline-block;
    height: 88px;
    margin: ${theme.space.inline.default};
    overflow: hidden;
    width: 100px;
    transition: all 0.5s;

    .homepage & {
      height: 60px;
      width: 60px;
    }
  }
  .toggle-icon {
    color: ${styles.mainNavbarColor};
    font-size: 3rem;
    cursor: pointer;
  }
  @media (min-width: ${styles.mediumScreenBreakpoint}) {
    .logoType {
      margin-top: 0.6rem;
      transform: scale(1);
      margin-left: 1rem;
    }
    .toggle-icon {
      display: none;
    }
    .type h1,
    .type h2 {
      color: ${props =>
        props.change ? styles.mainNavbarColor : styles.mainWhite};
    }
  }
`
