import React, { Component } from "react"
import { FaFacebookSquare } from "react-icons/fa"
import styled from "styled-components"
import { styles } from "../../globals"

export default class NavbarIcons extends Component {
  state = {
    icons: [
      {
        id: 0,
        icon: <FaFacebookSquare className="facebook-icon" />,
        path: "https://www.facebook.com/malufi193",
      },
      {
        id: 1,
        icon: (
          <div className="booking-icon">
            <span>B</span>
            <span className="booking-dot">. 193</span>
          </div>
        ),
        path: "https://www.booking.com/hotel/hr/malufi-193.en-gb.html",
      },
      {
        id: 2,
        icon: (
          <div className="booking-icon">
            <span>B</span>
            <span className="booking-dot">. 266</span>
          </div>
        ),
        path: "https://www.booking.com/hotel/hr/mobil-homes-malufi-266.en-gb.html",
      },
    ],
  }
  render() {
    return (
      <IconsWrapper>
        {this.state.icons.map(item => {
          return (
            <a
              href={item.path}
              key={item.id}
              style={{ textDecoration: "none" }}
            >
              {item.icon}
            </a>
          )
        })}
      </IconsWrapper>
    )
  }
}

const IconsWrapper = styled.div`
  margin-left: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: start;
  .icon {
    cursor: pointer;
    margin-top: 0.2rem;
    ${styles.transform({})};
  }
  .booking-icon {
    font-size: 1.2rem;
    background: #15357f;
    border-radius: 0px 6px;
    color: white;
    font-weight: 900;
    padding: 0.2rem;
    margin-right: 0.8rem;
  }
  .booking-dot {
    color: #2f9fe2;
  }
  .facebook-icon {
    margin-right: 0.8rem;
    margin-top: 0.2rem;
    font-size: 2rem;
  }
  @media (min-width: ${styles.mediumScreenBreakpoint}) {
    justify-content: space-between;
  }
`
