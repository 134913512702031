export const colors = {}

/* Colors */
export const mainBackgroundColor = `#ffffff`
export const mainNavbarBackgroundColor = `white`
export const mainNavbarColor = `#1c4058`
export const mainWhite = `white`
export const mainPink = `hotpink`
export const mainOrange = `#ff5700`

export const lighGrey = `675f5c`
export const darkGrey = `#675f5c`
export const veryDarkGrey = `#1d1c1c`

export const lightOrange = `#eebaa2`

/* Breakpoints */
export const mediumScreenBreakpoint = `1080px`
export const smallScreenBreakpoint = `768px`
export const xSmallScreenBreakpoint = `576px`

/* Functions */
export const letterSpacing = ({ spacing = "0.2rem" }) => {
  return `letter-spacing: ${spacing}`
}

export const transform = ({
  property = "all",
  time = "0.4s",
  type = "ease-in-out",
}) => {
  return `transition: ${property} ${time} ${type}`
}
