module.exports = {
  siteMetadata: {
    title: `Malufi Mobile Homes`,
    description: `Malufi Mobile Homes offers accommodation in Jezera, Murter (Croatia).Šibenik is 28 km from Malufi, while Vodice is 17 km from the property. The nearest airport is Zadar Airport, 69 km from the accommodation.`,
    keywords: [
      "Murter",
      "Jezera resort",
      "Dalmatia",
      "camp",
      "mobile",
      "cheap",
      "affordable",
      "beach",
    ],
    siteUrl: "https://malufi-mobile-homes.com", // No trailing slash allowed!
    author: `mkoron`,
  },
  plugins: [
    {
      resolve: `gatsby-plugin-styled-components`,
      options: {
        // Add any options here
      },
    },
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `pages`,
        path: `${__dirname}/src/pages/pagesMD/`,
      },
    },
    //`gatsby-plugin-complex-sitemap-tree`,
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-transformer-remark`,
      options: {
        plugins: [
          `gatsby-remark-relative-images`,
          {
            resolve: `gatsby-remark-images`,
            options: {
              // It's important to specify the maxWidth (in pixels) of
              // the content container as this plugin uses this as the
              // base for generating different widths of each image.
              maxWidth: 925,
              linkImagesToOriginal: false,
            },
          },
        ],
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `/`,
        background_color: `#1c4058`,
        theme_color: `#1c4058`,
        display: `minimal-ui`,
        icon: `static/icon/malufi-mobile-homes.png`, // This path is relative to the root of the site.
      },
    },
    {
      resolve: `gatsby-plugin-typography`,
      options: {
        pathToConfigModule: `src/globals/typography`,
      },
    },
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // 'gatsby-plugin-offline',
  ],
}
