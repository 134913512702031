import React from "react"
import { injectIntl } from "react-intl"
import { Link } from "gatsby"
import locales from "../../constants/locales"
import SelectLang from "../SelectLang"

function NavbarLangSwitch(props) {
  const languages = Object.keys(locales).map(key => (
    <Link lang={key} to={locales[key].default ? "/" : `/${locales[key].path}`}>
      {key}
    </Link>
  ))
  return <SelectLang items={languages} change={props.change} />
}

export default injectIntl(NavbarLangSwitch)
