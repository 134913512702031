import React, { Component } from "react"
import styled from "styled-components"
import { styles } from "../../globals"
import title from "../../../gatsby-config"

export default class Footer extends Component {
  render() {
    return (
      <FooterWraper>
        <div className="copyright">
          copyright &copy; {new Date().getFullYear()} {title.siteMetadata.title}
        </div>
      </FooterWraper>
    )
  }
}

const FooterWraper = styled.footer`
  height: 100px;
  background-image: radial-gradient(
      rgba(0, 0, 0, 0) 70%,
      #03bada calc(70% + 1px)
    ),
    linear-gradient(rgba(0, 0, 0, 0) calc(30px / 2), #03bada 0);
  background-size: 30px 30px, 1px 100%;
  background-position: 0 calc(30px / -2), 0 0;
  background-repeat: repeat-x;
  /*  animation: wave 8s linear infinite;

  @keyframes wave {
    0% {
      transform: translate(0, 0);
    }
    80% {
      transform: translate(0, 11px);
    }
    100% {
      transform: translate(0, 0);
      background-position-x: 30px;
    }
  } */

  /* display: flex;
  justify-content: center;
  flex-direction: column;
  background: radial-gradient(
      circle at 45% -30%,
      transparent 9%,
      rgba(255, 255, 255, 1) 10%,
      rgba(255, 255, 255, 1) 36%,
      transparent 0%,
      transparent
    )
    0 -100px;
  background-color: #b9dcff;
  background-size: 75px 100px;*/
  color: ${styles.mainWhite};
  font-size: 0.5rem;
  padding: 1.6rem 0.4rem;
  margin-top: 4rem;
  .copyright {
    margin-top: 0.8rem;
    font-weight: 900;
    font-size: 1.1rem;
    text-align: center;
    text-transform: capitalize;
  }
  @media (min-width: ${styles.mediumScreenBreakpoint}) {
    font-size: 1.1rem;
    padding: 2rem 4rem;
    margin-top: 8rem;
    .copyright {
      text-align: right;
    }
  }
`
